'use strict';

import 'picturefill';

import * as uif from './framework/base';
import {iframeResizerContentWindow} from 'iframe-resizer';

if (window.top != window.self) {
    document.querySelector('html').classList.add('iframe');
}

import SearchResult from './components/c01.02.01_search-result-header';

if (document.querySelector('.search-result-header')) {
    var searchResult = new SearchResult();
    searchResult.init();
}

import Top_nav from './components/c01.03_top-navigation';

if (document.querySelector('.top-navigation')) {
    var top_nav = new Top_nav();
    top_nav.init();
}

import Footerlinks from './components/c01.07_footer';

if (document.querySelector('.site-footer')) {
    var footerLinks = new Footerlinks();
    footerLinks.init(document.querySelector('.site-footer'));
}

import SocialLinks from './components/c01.08_social-links';

if (document.querySelector('.social-links')) {
    var newExample = new SocialLinks();
    newExample.init();
}

import languageSwitcher from './components/c01.12_regional-language-switcher';

if (document.querySelector('.regional-language-switcher')) {
    var newLanguageSwitcher = new languageSwitcher();
    newLanguageSwitcher.init();
}

import cookieBanner from './components/c01.13_cookie-banner';

if (document.querySelector('.cookie-notice')) {
    var newCookieBanner = new cookieBanner();
    newCookieBanner.init({
        setCookieName: 'cookie_accepted-' + document.querySelector('.cookie-notice').dataset.lang
    });
}

import regionLightbox from './components/c01.16_region-language-lightbox';

if (document.querySelector('.region-language-lightbox')) {
    var newRegionLightbox = new regionLightbox();
    newRegionLightbox.init();
}

import HeaderVideo from './components/c02.01_header-image-video';

if (document.querySelector('.video-banner') && uif.device() !== 'mobile') {
    var newVideo = new HeaderVideo();
    newVideo.init();
}

import PromoList from './components/c02.05_promo-list';

if (document.querySelector('.promo-list')) {
    var newList = new PromoList();
    newList.init();
}

import Video from './components/c02.06_video';

if (document.querySelector('.video')) {
    var newVideo = new Video();
    newVideo.init();
}

import GenericTabs from './components/c02.15_generic-tabs';

if (document.querySelector('.generic-tabs')) {
    var newTabs = new GenericTabs();
    newTabs.init();
}

import dynamicArticle from './components/c03.01.2_dynamic-article-list';

if (document.querySelector('.carousel-slides.dynamic-article')) {
    var newComponent = new dynamicArticle();
    newComponent.init();
}

import compactTabs from './components/c03.07_compact-tabs';

if (document.querySelector('.container--tabs')) {
    var newTabs = new compactTabs();
    newTabs.init();
}

import dynamicArticleNewsList from './components/c03.09_dynamic-news-list';

if (document.querySelector('.dynamics-news-list')) {
    var newComponent = new dynamicArticleNewsList();
    newComponent.init();
}

import Search_result from './components/c05.02_search-results-listing';

if (document.querySelector('.search-results-listing')) {
    var search_result = new Search_result();
    search_result.init();
}

import search_faceted_results from './components/c05.03_search-results-faceted-search-options';

if (document.querySelector('.search-results-faceted-search-options')) {
    var search_faceted = new search_faceted_results();
    search_faceted.init();
}

import officerFinder from './components/c06.01_office-finder';

if (document.querySelector('.office-finder-selector')) {
    var newOfficeFinder = new officerFinder();
    newOfficeFinder.init();
}

import Iframer from './components/c06.02_iframe';

import 'magnific-popup';
if (document.querySelector('.responsive-iframe')) {
    var newExample = new Iframer();
    newExample.init();
}

import localCountrySelector from './components/c07.12_local-country-service-selector';

if (document.querySelector('.local-country-service-selector')) {
    var newLocalCountrySelector = new localCountrySelector();
    newLocalCountrySelector.init();
}

import utilFilter from './components/c08.02_utilities-filter';

if (document.querySelector('.utilities-filter')) {
    var newUtilFilter = new utilFilter();
    newUtilFilter.init();
}

import utilKey from './components/c08.04_utilities-key';

if (document.querySelector('.utilities-key')) {
    var newUtilKey = new utilKey();
    newUtilKey.init();
}


// ZeppUI components
import './components/uif_tabs-3.0';
import './components/uif_vertical-accordion-1.3';
import './components/uif_lightbox-1.0.js';

import azureSearchShips  from './components/c09.01_azure-search';
azureSearchShips.init('.azure-search');

setTimeout(() => {
    if (document.querySelector('.ecerts-search') && document.querySelector('.search-no-results')) {
        window.scrollTo({
            top: document.querySelector('.ecerts-search').getBoundingClientRect().top + window.scrollY - 10,
            left: 0,
            behavior: 'smooth'
        });
    }
}, 600);


// Service Worker deactivated by default
// if ('serviceWorker' in navigator) {
//   window.addEventListener('load', function() {
//     navigator.serviceWorker.register('/serviceWorker.js').then(function(registration) {
//       // Registration was successful
//       console.log('ServiceWorker registration successful with scope: ', registration.scope);
//     }, function(err) {
//       // registration failed :(
//       console.log('ServiceWorker registration failed: ', err);
//     });
//   });
// }
